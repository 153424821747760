import { ModelInfo } from '../api/Models'

export default class PrismUtils {
    static getApiUrlForModelId(modelId: string) {
        return `${process.env.REACT_APP_API_URL}/route/${modelId}`
    }

    static isModelAsync(modelInfo: ModelInfo) {
        const modelType = (modelInfo.modelType || '').toLowerCase()
        return modelType === 'async'
    }
}
