import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import * as GlobalActions from '../redux/actions/GlobalActions'
import { connect } from 'react-redux'
import { Row, Col, Spin, Result, Button, Input } from 'antd'
import ApiManager from '../api/ApiManager'
import Toaster from '../ui/Toaster'
import FormContainer from './form/FormContainer'
import { PrismApiResponseData, PrismFormData } from '../api/Models'
import Logger from '../utils/Logger'
import CalculatedValues from './form/CalculatedValues'
import Utils from '../utils/Utils'

interface RootPageInterface extends RouteComponentProps<any> {
    rootElementKey: string
    emitSizeChanged: () => void
    isMobile: boolean
}

class PageRoot extends Component<
    RootPageInterface,
    {
        collapsed: boolean
        isLoading: boolean
        isModelLoadedError: boolean
        isApiCallLoading: boolean
        formData: PrismFormData | undefined
        returnedDataFromModelApi: undefined | PrismApiResponseData
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            isModelLoadedError: false,
            collapsed: false,
            isLoading: true,
            isApiCallLoading: false,
            formData: undefined,
            returnedDataFromModelApi: undefined
        }
    }

    updateDimensions = () => this.props.emitSizeChanged()

    componentWillUnmount() {
        if (super.componentWillUnmount) {
            super.componentWillUnmount()
        }
        this.updateDimensions()
        window.removeEventListener('resize', this.updateDimensions)
    }

    componentDidUpdate(prevProps: any) {
        // Typical usage (don't forget to compare props):
        if (this.props.location.pathname !== prevProps.location.pathname && this.props.isMobile) {
            this.setState({ collapsed: true })
        }
    }

    componentDidMount() {
        const modelUrl = new URLSearchParams(window.location.search).get('modelUrlKey')
        ApiManager.testUrl = modelUrl ? Utils.b64_to_utf8(modelUrl) : ApiManager.testUrl

        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions)
        this.fetchData()
    }

    fetchData() {
        const self = this

        self.setState({ returnedDataFromModelApi: undefined })
        new ApiManager()
            .getFormData() //
            .then(function (formData) {
                self.setState({ formData })
            })
            .catch((err) => {
                self.setState({ isModelLoadedError: true })
                Toaster.toast(err)
            })
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    render() {
        const self = this
        const formData = self.state.formData
        const returnedDataFromModelApi = self.state.returnedDataFromModelApi

        if (self.state.isModelLoadedError) {
            return (
                <div>
                    <Result
                        status="500"
                        title="Model Load Error"
                        subTitle="Sorry, something went wrong."
                        extra={
                            <Button
                                onClick={() => {
                                    const newPath = '/?modelUrlKey=' + Utils.utf8_to_b64(ApiManager.defaultUrl)
                                    window.location.replace(window.location.origin + newPath)
                                }}
                                type="primary"
                            >
                                Try something else
                            </Button>
                        }
                    />
                </div>
            )
        }

        if (self.state.isLoading || !formData) {
            return (
                <div>
                    <div style={{ height: 60 }} />
                    <Row align="middle" justify="center">
                        <span style={{ marginRight: 20 }}> Loading...</span> <Spin />
                    </Row>
                </div>
            )
        }

        return (
            <div style={{ padding: 30 }}>
                <Row justify="center">
                    <Input
                        style={{ marginBottom: 50 }}
                        addonBefore="Test URL"
                        defaultValue={ApiManager.testUrl}
                        value={ApiManager.testUrl}
                        onChange={(e) => {
                            ApiManager.testUrl = e.target.value.trim()
                            self.fetchData()
                        }}
                    />
                </Row>
                <Row justify="start">
                    <Col xs={{ span: 24 }}>
                        <FormContainer
                            key={ApiManager.testUrl}
                            isApiCallLoading={self.state.isApiCallLoading}
                            onSubmit={(data, emailAddress) => {
                                self.setState({ isApiCallLoading: true, returnedDataFromModelApi: undefined })
                                new ApiManager()
                                    .executeModelRunCall(data, emailAddress, formData.ModelInfo) //
                                    .then(function (returnedData) {
                                        Toaster.success('Model ran successfully')
                                        self.setState({ returnedDataFromModelApi: returnedData })
                                    })
                                    .catch(function (err) {
                                        Logger.error(err)
                                        Toaster.error('API returned an error')
                                    })
                                    .then(function () {
                                        self.setState({ isApiCallLoading: false })
                                    })
                            }}
                            formData={formData}
                        />
                    </Col>
                </Row>

                <Row>
                    {returnedDataFromModelApi && (
                        <div style={{ fontSize: 12, margin: 20 }}>
                            <CalculatedValues returnedDataFromModelApi={returnedDataFromModelApi} formData={formData} />
                        </div>
                    )}
                </Row>
                <div style={{ height: 30 }} />
            </div>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        rootElementKey: state.globalReducer.rootElementKey,
        isMobile: state.globalReducer.isMobile
    }
}

export default connect(mapStateToProps, {
    emitSizeChanged: GlobalActions.emitSizeChanged
})(PageRoot)
