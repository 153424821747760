import React, { Component, Fragment } from 'react'
import { Button, Col, Collapse, Input, Row, Tabs } from 'antd'
import { IHashMapGeneric, PrismFormData, PrismFormRow } from '../../api/Models'
import Toaster from '../../ui/Toaster'
import PrismInputField from './PrismInputField'
import Logger from '../../utils/Logger'
import Utils from '../../utils/Utils'
import PrismUtils from '../../utils/PrismUtils'

const { TabPane } = Tabs

export default class FormContainer extends Component<
    {
        formData: PrismFormData
        onSubmit: (dataEntered: IHashMapGeneric<any>, emailAddress: string) => void
        isApiCallLoading: boolean
    },
    {
        errors: IHashMapGeneric<string>
        activeTabKey: string
        enteredEmailAddress: string
    }
> {
    private dataEntered: IHashMapGeneric<any> = {}
    private touchedFields: IHashMapGeneric<boolean> = {}

    constructor(props: any) {
        super(props)

        this.props.formData.Items.forEach((row) => {
            this.dataEntered[row.ITEM_NAME] = Utils.getDefaultValue(row)
        })

        this.state = {
            errors: {},
            enteredEmailAddress: '',
            activeTabKey: props.formData.Items.length ? props.formData.Items[0].SECTION_LABEL : ''
        }
    }

    generateHeader(header: string) {
        if (header) {
            return <h3 style={{ marginTop: 35, marginLeft: 10 }}>{header}</h3>
        }
        return <span></span>
    }

    createError(row: PrismFormRow) {
        const self = this
        if (!self.state.errors[row.ITEM_NAME]) {
            return <span></span>
        }
        return <div style={{ color: 'red' }}>{self.state.errors[row.ITEM_NAME]}</div>
    }

    isRowHidden(r: PrismFormRow) {
        const hiddenRaw = `${r.HIDDEN || ''}`.trim().toLowerCase()
        return hiddenRaw && hiddenRaw !== '0' && hiddenRaw !== 'false'
    }

    generateRow(r: PrismFormRow) {
        const self = this
        const isHidden = this.isRowHidden(r)

        if (isHidden) {
            return undefined
        }

        return (
            <Fragment key={r.ITEM_NAME}>
                <Col sm={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 12 }}>
                    <div style={{ marginBottom: 10 }}>
                        {self.generateHeader(r.HEADER)}
                        <Row align="bottom">
                            <Col
                                xs={{ span: 16 }}
                                style={{ textAlign: 'right', marginTop: 'auto', marginBottom: 'auto', paddingRight: 10 }}
                            >
                                <span>{r.DESCRIPTION_LABEL}</span>
                            </Col>
                            <Col xs={{ span: 8 }}>
                                <span>
                                    <PrismInputField
                                        onChange={(value) => {
                                            self.dataEntered[r.ITEM_NAME] = value
                                            self.touchedFields[r.ITEM_NAME] = true
                                            self.runValidation(false)
                                        }}
                                        row={r}
                                    />
                                </span>
                            </Col>
                        </Row>
                        <Row justify="end">
                            <span>{self.createError(r)}</span>
                        </Row>
                    </div>
                </Col>
                <Col sm={{ span: 0 }} lg={{ span: 12 }} xl={{ span: 16 }} key={r.ITEM_NAME + '_'}></Col>
            </Fragment>
        )
    }

    generateRows(rows: PrismFormRow[]) {
        const self = this
        return rows.map((r) => {
            return self.generateRow(r)
        })
    }

    generateTabs() {
        const self = this

        const content: IHashMapGeneric<PrismFormRow[]> = {}
        const sections: string[] = []

        self.props.formData.Items.forEach((row) => {
            if (!content[row.SECTION_LABEL]) {
                content[row.SECTION_LABEL] = []
            }

            if (sections.indexOf(row.SECTION_LABEL) < 0) {
                sections.push(row.SECTION_LABEL)
            }

            content[row.SECTION_LABEL].push(row)
        })

        if (sections.length === 1) {
            const sect = sections[0]
            return (
                <div>
                    <Row justify="space-between" align="bottom" gutter={16}>
                        {self.generateRows(content[sect])}
                        {self.createDebugContainer(content, sect)}
                    </Row>
                </div>
            )
        }

        return (
            <Tabs
                activeKey={self.state.activeTabKey}
                style={{ padding: 20 }}
                onChange={(key) => {
                    self.setState({ activeTabKey: key })
                }}
            >
                {sections.map((sect) => {
                    return (
                        <TabPane tab={sect} key={sect}>
                            <Row justify="space-between" align="bottom" gutter={16}>
                                {self.generateRows(content[sect])}
                                {self.createDebugContainer(content, sect)}
                            </Row>
                        </TabPane>
                    )
                })}
            </Tabs>
        )
    }

    createDebugContainer(content: IHashMapGeneric<PrismFormRow[]>, sect: string) {
        if (Utils.isDebug()) {
            return (
                <Collapse>
                    <Collapse.Panel header="Section Content <Debug>" key="debug">
                        <div style={{ fontSize: 12, marginTop: 20, backgroundColor: '#f9f9f9' }}>
                            <pre style={{}}>{JSON.stringify(content[sect], null, 2)}</pre>
                        </div>
                    </Collapse.Panel>
                </Collapse>
            )
        }
    }

    runValidation(forceValidateAllFields: boolean) {
        const self = this
        const oldErrors = self.state.errors

        const rowsToChecked = self.props.formData.Items.filter((it) => {
            if (self.isRowHidden(it)) {
                return false
            }

            if (forceValidateAllFields) {
                return true
            }

            return !!self.touchedFields[it.ITEM_NAME] || !!oldErrors[it.ITEM_NAME]
        })

        const newErrors: IHashMapGeneric<string> = {}

        rowsToChecked.forEach((row) => {
            const currentData = self.dataEntered[row.ITEM_NAME].trim()

            if (`${row.DATA_TYPE}`.trim().toUpperCase() === 'INT') {
                if (
                    parseInt(currentData, 10) !== parseFloat(currentData) ||
                    isNaN(Number(currentData)) ||
                    isNaN(parseInt(currentData, 10))
                ) {
                    newErrors[row.ITEM_NAME] = 'Must be an integer'
                    return
                }
            } else if (`${row.DATA_TYPE}`.trim().toUpperCase() === 'ST') {
                Logger.error('Error for ST has not been implemented yet!')
            } else {
                Logger.error(`Unknown DATA_TYPE of ${row.DATA_TYPE} for ${row.ITEM_NAME}`)
            }

            const validation = `${row.VALIDATION || ''}`.trim().replace('func:', '')
            if (!validation) {
                return
            }

            const isValid = Utils.unsafeEval(validation.split('val').join(currentData))
            if (!isValid) {
                newErrors[row.ITEM_NAME] = row.VALIDATION_ERROR_MESSAGE || 'Error'
            }
        })

        // self.setState({ errors: { 'global_parameters.age0': 'Testing my error' } })

        self.setState({ errors: newErrors })

        return Object.keys(newErrors).length === 0
    }

    getCleanData(data: IHashMapGeneric<any>) {
        const dataCleaned: IHashMapGeneric<any> = {}
        const self = this
        Object.keys(data).forEach((k) => {
            const dataType = self.props.formData.Items.find((it) => it.ITEM_NAME === k)?.DATA_TYPE

            if (!dataType) {
                Logger.error(`Field not found for ${k}`)
                return
            }

            if (dataType === 'INT') {
                dataCleaned[k] = parseInt(data[k], 10)
            } else if (dataType === 'ST') {
                dataCleaned[k] = data[k]
                dataCleaned[k] = `${dataCleaned[k]}`.split('"').join('')
            } else {
                Logger.error(`Unknown DATA_TYPE of ${dataType} for ${k}`)
            }
        })
        return dataCleaned
    }

    ensureValidEmail(emailString: string) {
        emailString = (emailString || '').trim()
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailString) ? emailString : false
    }

    createSubmitButton() {
        const self = this

        if (PrismUtils.isModelAsync(self.props.formData.ModelInfo)) {
            return (
                <div>
                    <Row justify="end">
                        <Input
                            style={{ marginBottom: 10, maxWidth: 320, marginRight: 35 }}
                            addonBefore="Your Email"
                            defaultValue={'your@email.com'}
                            value={self.state.enteredEmailAddress}
                            onChange={(e) => {
                                self.setState({ enteredEmailAddress: e.target.value })
                            }}
                        />
                    </Row>
                    <Row justify="end">
                        <Button
                            size="large"
                            style={{ paddingLeft: 65, paddingRight: 65, marginRight: 35 }}
                            shape="round"
                            type="primary"
                            disabled={!self.ensureValidEmail(self.state.enteredEmailAddress)}
                            loading={this.props.isApiCallLoading}
                            onClick={() => {
                                const isAllValid = self.runValidation(true)
                                if (!isAllValid) {
                                    Toaster.error('Please resolve the errors first!')
                                    return
                                }
                                self.submitData()
                            }}
                        >
                            Submit
                        </Button>
                    </Row>
                </div>
            )
        }

        return (
            <Row justify="end">
                <Button
                    size="large"
                    style={{ paddingLeft: 65, paddingRight: 65, marginRight: 65 }}
                    shape="round"
                    type="primary"
                    loading={this.props.isApiCallLoading}
                    onClick={() => {
                        const isAllValid = self.runValidation(true)
                        if (!isAllValid) {
                            Toaster.error('Please resolve the errors first!')
                            return
                        }
                        self.submitData()
                    }}
                >
                    Submit
                </Button>
            </Row>
        )
    }

    submitData() {
        const self = this
        self.props.onSubmit(self.getCleanData(self.dataEntered), this.ensureValidEmail(self.state.enteredEmailAddress) || '')
    }

    render() {
        const self = this

        return (
            <div>
                <Row>
                    <h1>{self.props.formData.ModelInfo.modelName || '<Model Name Missing>'}</h1>
                </Row>
                <Row>{self.generateTabs()}</Row>
                {self.createSubmitButton()}
            </div>
        )
    }
}
