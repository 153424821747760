import { Input, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { Component } from 'react'
import { PrismFormRow } from '../../api/Models'
import Logger from '../../utils/Logger'
import Utils from '../../utils/Utils'
const { Option } = Select

export default class PrismInputField extends Component<
    {
        row: PrismFormRow
        onChange: (value: string) => void
    },
    {}
> {
    render() {
        const self = this
        const r = this.props.row

        const inputType = `${r.RESPONSE_TYPE}`.trim().toLowerCase()
        if (inputType === 'text') {
            return (
                <Input
                    style={{ fontFamily: 'mono' }}
                    defaultValue={r.DEFAULT_VALUE}
                    onChange={(v) => {
                        self.props.onChange(`${v.target.value}`)
                    }}
                />
            )
        }

        if (inputType === 'single-select') {
            return (
                <Select
                    style={{ fontFamily: 'mono', width: '100%' }}
                    defaultValue={Utils.getDefaultValue(r)}
                    onChange={(v) => {
                        self.props.onChange(`${v}`)
                    }}
                >
                    {self.generateOptions()}
                </Select>
            )
        }

        if (inputType === 'matrix') {
            return self.generateTextbox()
        }

        return (
            <div style={{ fontFamily: 'mono' }}>
                Not known {r.ITEM_NAME} {r.RESPONSE_TYPE}
            </div>
        )
    }

    generateTextbox() {
        const self = this
        const r = this.props.row
        const defaultValue = `${r.DEFAULT_VALUE}`
            .split(';')
            .map((it) => it.trim())
            .join('\n')
        return (
            <div style={{ fontFamily: 'mono' }}>
                <TextArea
                    style={{ whiteSpace: 'nowrap', overflowX: 'scroll' }}
                    rows={defaultValue.split('\n').length}
                    defaultValue={defaultValue}
                    onChange={(v) => {
                        self.props.onChange(`${v}`.split('\n').join(';'))
                    }}
                ></TextArea>
            </div>
        )
    }

    generateOptions() {
        const self = this
        const r = this.props.row
        const optionKeys = self.getOptionKeys()
        const showValues = r.RESPONSE_OPTIONS_TEXT.split(',').map((it) => it.trim())

        if (optionKeys.length !== showValues.length) {
            return [true].map((it) => {
                Logger.error(`Length Mismatch: ${JSON.stringify(optionKeys)} ${JSON.stringify(r.RESPONSE_OPTIONS_TEXT)} `)
                return (
                    <Option key="ERROR_option" value="ERROR">
                        ERROR
                    </Option>
                )
            })
        }

        return optionKeys
            .map(function (key, idx) {
                return [key, showValues[idx]]
            })
            .map((it) => {
                return (
                    <Option value={it[0]} key={it[0]}>
                        {it[1]}
                    </Option>
                )
            })
    }

    getOptionKeys() {
        const self = this
        const r = self.props.row
        return `${r.RESPONSE_VALUES_OR_CALCULATIONS}`.split(',').map((it) => it.trim())
    }
}
