import Axios from 'axios'
import Logger from '../utils/Logger'
import PrismUtils from '../utils/PrismUtils'
import { IHashMapGeneric, ModelInfo, PrismApiResponseData, PrismFormData } from './Models'

export default class ApiManager {
    static readonly defaultUrl =
        'https://gist.githubusercontent.com/githubsaturn/e4393a7bda9a220e9e4445b19293c00d/raw/de0963a146756a2623032cd0ae437fcbac1561bd/accept.json'
    static testUrl = [
        // aHR0cHM6Ly9naXN0LmdpdGh1YnVzZXJjb250ZW50LmNvbS9naXRodWJzYXR1cm4vZTQzOTNhN2JkYTlhMjIwZTllNDQ0NWIxOTI5M2MwMGQvcmF3LzA5OWZhZWYyYTFhYTdiNTRlZjRkNmI1ZjQ4NjkzZjU1ZWEwNDBjMTMvYWNjZXB0Lmpzb24%3D
        'https://gist.githubusercontent.com/githubsaturn/e4393a7bda9a220e9e4445b19293c00d/raw/de0963a146756a2623032cd0ae437fcbac1561bd/accept.json',
        'https://gist.githubusercontent.com/githubsaturn/4e878f320a72445f04dacd744c65c262/raw/baaa33f1a5b54660344eb61dd4cf2834c71f0472/epic.json'
    ][0] //

    getFormData(): Promise<PrismFormData> {
        const headers = {} as any
        headers[`Content-Type`] = `application/json`
        return Promise.resolve() //
            .then(function () {
                return Axios.get(
                    // `https://gist.githubusercontent.com/githubsaturn/4e878f320a72445f04dacd744c65c262/raw/8c9b2202fcf219045fddd81acedfaa7da81fede9/epic.json`,
                    ApiManager.testUrl,
                    {
                        headers
                    }
                )
            })
            .then(function (response) {
                return response.data
            })
            .then(function (responseData: PrismFormData) {
                if (!responseData.ModelInfo || !responseData.ModelInfo.modelId) {
                    throw new Error('Bad model definition')
                }
                return responseData
            })
    }

    executeModelRunCall(data: IHashMapGeneric<any>, email: string, modelInfo: ModelInfo): Promise<PrismApiResponseData> {
        const headers = {} as any
        headers[`x-prism-auth-user`] = `${modelInfo.authKey}`
        headers[`Content-Type`] = 'application/json'
        const dataToSend: any = {
            func: ['prism_model_run'],
            model_input: [
                data
                // {
                //     ID: '10001',
                //     male: 1,
                //     age: 57,
                //     smoker: 0,
                //     oxygen: 0,
                //     statin: 0,
                //     LAMA: 1,
                //     LABA: 1,
                //     ICS: 1,
                //     FEV1: 51,
                //     BMI: 18,
                //     SGRQ: 63,
                //     LastYrExacCount: 2,
                //     LastYrSevExacCount: 1,
                //     randomized_azithromycin: 0,
                //     randomized_statin: 0,
                //     randomized_LAMA: 0,
                //     randomized_LABA: 0,
                //     randomized_ICS: 0,
                //     random_sampling_N: 1000,
                //     random_distribution_iteration: 20000,
                //     calculate_CIs: 'TRUE'
                // }
            ]
        }

        if (PrismUtils.isModelAsync(modelInfo)) {
            dataToSend.email_address = [email || '']
        }

        return Promise.resolve() //
            .then(function () {
                return Axios.post(
                    PrismUtils.getApiUrlForModelId(modelInfo.modelId) + `/${PrismUtils.isModelAsync(modelInfo) ? 'async/run' : 'run'}`,
                    dataToSend,
                    {
                        headers
                    }
                )
            })
            .then(function (response) {
                return { headersResponse: response.headers, data: response.data }
            })
            .then(function (response) {
                const responseData = response.data
                const headersResponse = response.headersResponse
                if (responseData.length === 1) {
                    // weird response of Prism models.
                    // tslint:disable: max-line-length
                    // curl 'https://prism.peermodelsnetwork.com/route/accept/run' \
                    // -H 'authority: prism.peermodelsnetwork.com' \
                    // -H 'sec-ch-ua: " Not;A Brand";v="99", "Google Chrome";v="91", "Chromium";v="91"' \
                    // -H 'accept: application/json, text/plain, */*' \
                    // -H 'x-prism-auth-user: aaHYJJb4hcrmBYY3' \
                    // -H 'sec-ch-ua-mobile: ?0' \
                    // -H 'user-agent: Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.114 Safari/537.36' \
                    // -H 'content-type: application/json' \
                    // -H 'origin: http://localhost:4500' \
                    // -H 'sec-fetch-site: cross-site' \
                    // -H 'sec-fetch-mode: cors' \
                    // -H 'sec-fetch-dest: empty' \
                    // -H 'referer: http://localhost:4500/' \
                    // -H 'accept-language: en-US,en;q=0.9,ar-EG;q=0.8,ar;q=0.7,fa-IR;q=0.6,fa;q=0.5,es-US;q=0.4,es;q=0.3,fr-CA;q=0.2,fr;q=0.1' \
                    // --data-raw '{"func":["prism_model_run"],"model_input":[{"ID":"10001","male":1,"age":70,"smoker":1,"oxygen":0,"statin":1,"LAMA":1,"LABA":1,"ICS":1,"FEV1":33,"BMI":25,"SGRQ":33,"LastYrExacCount":2,"LastYrSevExacCount":1,"randomized_azithromycin":0,"randomized_statin":0,"randomized_LAMA":0,"randomized_LABA":0,"randomized_ICS":0,"random_sampling_N":100,"calculate_CIs":"TRUE","random_distribution_iteration":20000}]}' \
                    // --compressed

                    return { responseHeaders: headersResponse, data: JSON.parse(responseData[0]) }
                }
                Logger.error(responseData)
                throw new Error('Bad data')
            })
    }
}
