import { Input, Row } from 'antd'
import React, { Component } from 'react'
import Utils from '../utils/Utils'

export default class PageAdmin extends Component {
    state = {
        url: ''
    }
    render() {
        const self = this

        const pathUrl = window.location.origin + `/?modelUrlKey=${Utils.utf8_to_b64(self.state.url)}`

        return (
            <div>
                <div style={{ padding: 30 }}>
                    <Row justify="center">
                        <Input
                            style={{ margin: 50 }}
                            addonBefore="Target Model URL"
                            onChange={(e) => {
                                self.setState({ url: e.target.value })
                            }}
                        />
                    </Row>
                    <Row justify="center">
                        <pre>{pathUrl}</pre>
                    </Row>
                </div>
            </div>
        )
    }
}
