import { Collapse, Spin } from 'antd'
import React, { Component } from 'react'
import { IHashMapGeneric, OutputItem, PrismApiResponseData, PrismFormData } from '../../api/Models'
import PrismUtils from '../../utils/PrismUtils'
import Utils from '../../utils/Utils'

const imageWidth = 450
export default class CalculatedValues extends Component<
    {
        returnedDataFromModelApi: PrismApiResponseData
        formData: PrismFormData
    },
    {
        imgSources: IHashMapGeneric<any>
    }
> {
    constructor(props: { returnedDataFromModelApi: PrismApiResponseData; formData: PrismFormData }) {
        super(props)
        const imgSources: IHashMapGeneric<any> = {}
        props.formData.OutputItems.filter((it) => it.Type === 'graph').forEach((it) => {
            imgSources[it.valName] = true
        })
        this.state = { imgSources }
    }

    getImageUrl(imageId: string) {
        return (
            PrismUtils.getApiUrlForModelId(this.props.formData.ModelInfo.modelId) +
            '/tmp/' +
            this.props.returnedDataFromModelApi?.responseHeaders['x-ocpu-session'] +
            '/graphics/' +
            imageId.replace('graph_', '')
        )
    }

    componentDidMount() {
        const self = this
        return this.props.formData.OutputItems.filter((it) => it.Type === 'graph').forEach((it) => {
            self.fetchImage(it.valName)
        })
    }

    fetchImage(imageId: string) {
        const self = this
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                const newSource = { ...self.state.imgSources }
                newSource[imageId] = URL.createObjectURL(xhr.response)
                self.setState({ imgSources: newSource })
            }
        }
        xhr.open('GET', self.getImageUrl(imageId), true)
        xhr.setRequestHeader('x-prism-auth-user', this.props.formData.ModelInfo.authKey)
        xhr.send()
    }

    createImage(imageId: string) {
        if (this.state.imgSources[imageId] === true) {
            return (
                <div>
                    <Spin style={{ width: imageWidth }} size="large" key={imageId} />
                </div>
            )
        }
        return <img height={imageWidth} alt="" key={imageId} src={this.state.imgSources[imageId]} />
    }

    createImages() {
        const self = this
        return Object.keys(this.state.imgSources) //
            .map((it) => self.createImage(it))
    }

    createRow(item: OutputItem) {
        const self = this
        return (
            <div>
                <pre>
                    <b> {item.Title}:</b> {self.props.returnedDataFromModelApi.data[item.valName][0]}
                </pre>
            </div>
        )
    }

    createRows() {
        const self = this

        return this.props.formData.OutputItems.filter((it) => it.Type === 'number').map((it) => self.createRow(it))
    }

    createDebugContainer() {
        const self = this
        if (Utils.isDebug()) {
            return (
                <Collapse>
                    <Collapse.Panel header="Section Content <Debug>" key="debug">
                        <pre style={{}}>{JSON.stringify(self.props.returnedDataFromModelApi, null, 2)}</pre>
                    </Collapse.Panel>
                </Collapse>
            )
        }
    }

    render() {
        const self = this

        return (
            <div>
                <div style={{ height: 50 }} />
                {self.createRows()}
                <div style={{ height: 30 }} />
                {self.createImages()}
                {self.createDebugContainer()}
            </div>
        )
    }
}
