import { PrismFormRow } from '../api/Models'
import Logger from './Logger'

/* eslint-disable no-eval */
export default {
    copyObject<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj)) as T
    },

    isMobile() {
        return window.innerWidth < 768
    },

    isDebug() {
        return `${process.env.REACT_APP_IS_DEBUG || ''}`.toLocaleLowerCase().trim() === 'true'
    },

    scrollDown(px: number) {
        setTimeout(() => {
            window.scrollBy({
                top: px,
                left: 0,
                behavior: 'smooth'
            })
        }, 300)
    },

    utf8_to_b64(str: string) {
        let res = ''
        try {
            res = encodeURIComponent(window.btoa(unescape(encodeURIComponent(str))))
        } catch (error) {
            Logger.error(error)
        }

        return res
    },

    b64_to_utf8(str: string) {
        let res = ''
        try {
            res = decodeURIComponent(escape(window.atob(decodeURIComponent(str))))
        } catch (error) {
            Logger.error(error)
        }

        return res
    },

    getDefaultValue(row: PrismFormRow) {
        if (`${row.RESPONSE_TYPE}`.trim().toLowerCase() === 'single-select') {
            return `${row.DEFAULT_VALUE}` || `${row.RESPONSE_VALUES_OR_CALCULATIONS}`.split(',').map((it) => it.trim())[0]
        } else if (`${row.RESPONSE_TYPE}`.trim().toLowerCase() === 'matrix') {
            return `${row.DEFAULT_VALUE}`
        } else if (`${row.RESPONSE_TYPE}`.trim().toLowerCase() === 'text') {
            return `${row.DEFAULT_VALUE}`
        } else {
            Logger.error(`Unrecognized type: ${row.ITEM_NAME} because: ${row.RESPONSE_TYPE}`)
            return ''
        }
    },

    unsafeEval(inputStr: string) {
        // tslint:disable-next-line:no-eval
        return eval(inputStr)
    },

    getDelayedPromise(time: number) {
        if (!time) {
            return Promise.resolve()
        }

        return new Promise<void>((res, rej) => {
            setTimeout(() => {
                res()
            }, time)
        })
    }
}
